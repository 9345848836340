/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-undef */
import React, { useState } from "react"
import Cookies from "js-cookie"
import { useQuery } from "@apollo/client"
import { useTranslation } from "react-i18next"
import { useDropzone } from "react-dropzone"
import CircularProgress from "@mui/material/CircularProgress"
import Paper, { PaperProps } from "@mui/material/Paper"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import Chip from "@mui/material/Chip"
import Autocomplete from "@mui/material/Autocomplete"
import difference from "lodash/difference"
import { Descendant } from "slate"
import Draggable from "react-draggable"

import SnackbarMessage from "~/components/SnackbarMessage"
import EmailAttachments from "~/components/EmailAttachments"
import RichTextComposer from "~/components/RichTextEditor/RichTextComposer"
import ErrorMessage from "~/components/ErrorMessage"
import SelectorField from "~/components/SelectorField"
import FielderTextField from "~/components/FielderTextField"
import FieldHelperText from "~/components/FieldHelperText"
import { flattenObject, isValidEmail, resolveTemplate, toHtml, ONE_MEGABYTE, isBlank } from "~/util"
import { GET_DATA_FOR_JOB_EMAIL_TEMPLATES } from "~/queries/getDataForJobEmailTemplates"
import { useAuth } from "~/context/AuthContext"
import {
  Attachment,
  EmailTemplate,
  Invoice,
  Job,
  Estimate,
  TemplateData,
  Checklist,
  AttachmentType,
  Snack,
  WorkOrder,
} from "~/types"
import useGetUserOrganizationPreferences from "~/hooks/useGetUserOrganizationPreferences"

const ATTACHMENT_SIZE_LIMIT = 20 * ONE_MEGABYTE

const INITIAL_BODY_VALUE: Descendant[] = [
  {
    type: "paragraph",
    children: [
      {
        text: "",
        type: "paragraph",
      },
    ],
  },
] as Descendant[]

interface JobEmailMessage {
  jobId: string
  toRecipients: string[]
  ccRecipients?: string[]
  bccRecipients?: string[]
  subject?: string
  body?: string
  attachmentIds?: string[]
  estimateIds?: string[]
  invoiceIds?: string[]
}

function PaperComponent(props: PaperProps) {
  return (
    <Draggable
      bounds={{ top: -100 }}
      cancel={'[class*="MuiDialogContent-root"]'}
      handle=".drag-handle"
    >
      <Paper {...props} />
    </Draggable>
  )
}

interface Props {
  readonly checklist?: Checklist
  readonly invoice?: Invoice
  readonly job: Job
  readonly loading?: boolean
  readonly onCancel: () => void
  readonly onSend: (messageData: JobEmailMessage) => void
  readonly estimate?: Estimate
}

function JobEmailDialog({ checklist, invoice, job, loading, onCancel, onSend, estimate }: Props) {
  const { user } = useAuth()
  const organizationPreferences = useGetUserOrganizationPreferences(user?.organization?.id)
  const { t } = useTranslation()
  const {
    getRootProps,
    getInputProps,
    open: openFileDialog,
  } = useDropzone({
    accept: {
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
      "text/plain": [".txt"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    },
    minSize: 0,
    maxSize: ATTACHMENT_SIZE_LIMIT,
    multiple: true,
    noClick: true,
    onDrop: (acceptedFiles) => {
      uploadFiles(acceptedFiles)
    },
    onDropRejected: (rejectedFiles) => {
      if (rejectedFiles.find((f) => f.errors[0].code === "file-too-large")) {
        const rejectedFile = rejectedFiles[0].file
        setSnack({
          messageKey: "component.jobEmailDialog.error.file-upload.not-allowed.size",
          messageOptions: { fileName: rejectedFile.name },
          variant: "error",
        })
      }
    },
  })
  const [snack, setSnack] = useState<Snack>()
  const [uploadingNewAttachmentFiles, setUploadingNewAttachmentFiles] = useState(false)
  const [estimates, setEstimates] = useState<Estimate[]>([])
  const [workOrders, setWorkOrders] = useState<WorkOrder[]>([])
  const [invoices, setInvoices] = useState<Invoice[]>([])
  const [emailTemplateData, setEmailTemplateData] = useState<TemplateData>()
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([])
  const [emailTemplate, setEmailTemplate] = useState<EmailTemplate>()
  const [attachments, setAttachments] = useState<Attachment[]>([])
  const [tempRecipient, setTempRecipient] = useState<string>("")
  const [toRecipients, setToRecipients] = useState<string[]>(() => {
    const contacts = []
    if (estimate?.billingContact?.email) {
      contacts.push(estimate?.billingContact?.email)
    }

    if (invoice?.billingContact?.email && !contacts.includes(invoice?.billingContact?.email)) {
      contacts.push(invoice?.billingContact?.email)
    }

    if (job?.siteContact?.email && !contacts.includes(job?.siteContact?.email)) {
      contacts.push(job?.siteContact?.email)
    }

    return contacts
  })
  const [ccRecipients, setCcRecipients] = useState<string[]>([])
  const [bccRecipients, setBccRecipients] = useState<string[]>(() => {
    const emails = []
    if (organizationPreferences?.bccJobEmailToOrganizationEmail) {
      if (user?.organization?.email) {
        emails.push(user.organization.email)
      }

      // if (user?.email && !emails.includes(user.email)) {
      //   emails.push(user.email)
      // }
    }

    return emails
  })
  const [subject, setSubject] = useState<string>(() => {
    if (job.address?.streetNumber && job.address?.route) {
      return t("jobEmailDefaultSubject", {
        address: `${job.address.streetNumber} ${job.address.route}`,
      })
    } else {
      return `${t("job")} #${job.number}`
    }
  })
  const [initialBody, setInitialBody] = useState<Descendant[]>(INITIAL_BODY_VALUE)
  const [body, setBody] = useState<Descendant[]>(INITIAL_BODY_VALUE)
  const [errors, setErrors] = useState(() => ({
    general: null,
    toRecipients: null,
    subject: null,
  }))

  const { loading: dataLoading, refetch } = useQuery(GET_DATA_FOR_JOB_EMAIL_TEMPLATES, {
    variables: { id: job.id },
    fetchPolicy: "no-cache", // without no-cache, the dialog closes automatically after a new Estimate finishes loading
    onCompleted: (data) => {
      const apiData = data?.getJobById
      const jobData = {
        id: apiData?.id,
        number: apiData?.number,
        description: apiData?.description,
        desiredCompletionDate: apiData?.desiredCompletionDate,
        emailAddress: apiData?.emailAddress,
        address: apiData?.address,
        checklist: apiData?.checklist,
        siteContact: apiData?.siteContact,
        customer: apiData?.customer,
      }

      const rawEstimates = apiData?.estimates ?? []
      setEstimates(rawEstimates)

      const rawWorkOrders = apiData?.workOrders ?? []
      setWorkOrders(rawWorkOrders)

      const rawInvoices = apiData?.invoices ?? []
      setInvoices(rawInvoices)

      const availableFiles = apiData?.attachments?.filter(
        (a: Attachment) => !a.isArchived && (a.sizeInBytes ?? 0) < ATTACHMENT_SIZE_LIMIT
      )
      console.log("availableFiles", availableFiles)

      const estimateFiles = rawEstimates?.map((e: Estimate) => {
        // Create a fake Attachment for the Estimate, using the estimate's number as the name
        return {
          id: e.id,
          type: "ESTIMATE",
          contentType: "application/pdf",
          isArchived: false,
          name:
            e.pdfFileName && !isBlank(e.pdfFileName)
              ? e.pdfFileName
              : `${t("fileName.estimate", { number: e.number })}.pdf`,
          isSelected: e.id === estimate?.id,
        }
      })
      const workOrderFiles = rawWorkOrders?.map((w: WorkOrder) => {
        // Create a fake Attachment for the Estimate, using the estimate's number as the name
        return {
          id: w.id,
          type: "WORK_ORDER",
          contentType: "application/pdf",
          isArchived: false,
          name:
            w.pdfFileName && !isBlank(w.pdfFileName)
              ? w.pdfFileName
              : `${t("fileName.workOrder", { number: w.number })}.pdf`,
        }
      })
      // Create a fake Attachment for the Invoice, using the invoice's number as the name
      const invoiceFiles = rawInvoices?.map((i: Invoice) => {
        return {
          id: i.id,
          type: "INVOICE",
          contentType: "application/pdf",
          isArchived: false,
          name:
            i.pdfFileName && !isBlank(i.pdfFileName)
              ? i.pdfFileName
              : `${t("fileName.invoice", { number: i.number })}.pdf`,
          isSelected: i.id === invoice?.id,
        }
      })

      const orgFiles = apiData?.organization?.attachments?.filter((a: Attachment) => !a.isArchived)
      let allFiles = estimateFiles
        .concat(workOrderFiles)
        .concat(invoiceFiles)
        .concat(availableFiles)
        .concat(orgFiles)

      if (apiData?.checklist?.id) {
        // Create a fake Attachment for the Checklist
        allFiles = allFiles.concat({
          id: apiData.checklist.id,
          type: "CHECKLIST",
          contentType: "application/pdf",
          isArchived: false,
          name: `${t("fileName.checklist")}.pdf`,
        })
      }
      setAttachments(allFiles)

      if (estimate) {
        setEmailTemplates(
          apiData?.organization?.emailTemplates?.filter((t: EmailTemplate) => t.type === "ESTIMATE")
        )
      } else if (invoice) {
        setEmailTemplates(
          apiData?.organization?.emailTemplates?.filter((t: EmailTemplate) => t.type === "INVOICE")
        )
      } else {
        setEmailTemplates(
          apiData?.organization?.emailTemplates?.filter((t: EmailTemplate) => t.type === "JOB")
        )
      }

      const templateData: TemplateData = {
        job: jobData,
        organization: apiData?.organization,
        estimate: estimates?.find((e: Estimate) => e.id === estimate?.id),
        invoice: invoices?.find((i: Invoice) => i.id === invoice?.id),
      }

      const flattenedTemplateData = flattenObject(templateData, "", [
        "attachments",
        "estimates",
        "invoices",
        "emailTemplates",
        "lineItems",
        "__typename",
      ])

      setEmailTemplateData(flattenedTemplateData)
    },
  })

  function validate() {
    if (errors.toRecipients || errors.subject) {
      return false
    }

    if (
      !toRecipients ||
      toRecipients.length === 0 ||
      !subject ||
      subject.trim().length === 0 ||
      !body ||
      body.length === 0
    ) {
      return false
    }

    return true
  }

  const uploadFile = async (file: File) => {
    const data = new FormData()
    data.append("file", file)
    return fetch(`${process.env.GATSBY_API_SERVER_URL}/attachment/email`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${Cookies.get("authToken")}`,
      },
      body: data,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.errorCode) {
          setSnack({
            messageKey: `component.jobEmailDialog.error.${responseJson.errorCode}`,
            messageOptions: { fileName: file.name },
            variant: "error",
          })
        } else {
          const attachment = responseJson.attachment
          const newAttachmentId = attachment?.id
          if (newAttachmentId) {
            setAttachments((prev) => [
              ...prev,
              {
                ...attachment,
                isSelected: true,
                type: AttachmentType.EMAIL,
              },
            ])
          }
        }
      })
  }

  const uploadFiles = async (files: File[]) => {
    if (files.length > 0) {
      const promises = files.map((file) => uploadFile(file))
      setUploadingNewAttachmentFiles(true)
      return await Promise.all(promises)
        .then(() => {
          refetch()
        })
        .catch((error) => {
          console.error("error: ", error)
        })
        .finally(() => {
          setUploadingNewAttachmentFiles(false)
        })
    } else {
      return []
    }
  }

  /**
   * This function will simply dump everything in the attachmentIds, without concern as to whether
   * the attachmentId represents an Estimate or Invoice.
   * The SEND button's click handler will separate the estimate and invoice IDs from the "general" attachment IDs.
   */
  const toggleAttachment = (attachmentId: string) => {
    setAttachments((prev) => {
      return prev.map((a) => {
        if (a.id === attachmentId) {
          return { ...a, isSelected: !a.isSelected }
        }
        return a
      })
    })
  }

  return (
    <Dialog
      PaperComponent={PaperComponent}
      aria-labelledby="compose-email-dialog"
      data-testid="JobEmailDialog"
      disableEscapeKeyDown
      fullWidth
      maxWidth="lg"
      onClose={(event, reason) => {
        if (reason === "escapeKeyDown") {
          onCancel?.()
        }
      }}
      open
    >
      <DialogTitle
        className="drag-handle"
        sx={{
          py: "0.625rem",
          px: "1.5rem",
          cursor: "move",
          backgroundColor: (theme) => theme.palette.primary.main,
        }}
      >
        {estimate ? t("sendEstimate") : t("sendEmail")}
      </DialogTitle>
      <DialogContent>
        {snack ? <SnackbarMessage onClose={() => setSnack(undefined)} snack={snack} /> : null}
        {errors.general ? (
          <ErrorMessage message={t("error.general.message")} title={t("error.general.title")} />
        ) : null}
        <Box
          sx={() => {
            const base = {
              backgroundColor: dialogBackgroundColor,
              padding: "1rem",
              marginTop: "1.5rem",
              marginBottom: "0.5rem",
              display: "flex",
              flexDirection: "row",
            }
            return dataLoading
              ? {
                  ...base,
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }
              : base
          }}
        >
          {dataLoading ? (
            <>
              <CircularProgress color="secondary" size={40} thickness={6.0} />
              <p>{t("component.emailDialog.loadingDataMessage")}</p>
            </>
          ) : (
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={classes.fieldContainer}>
                <SelectorField
                  emptyOptionLabel={t("component.emailDialog.noEmailTemplates") as string}
                  label={t("emailTemplate") as string}
                  margin="dense"
                  name="emailTemplate"
                  onChange={(selectedOption) => {
                    if (selectedOption && emailTemplateData) {
                      setSubject(selectedOption.subject)
                      const templateBody = JSON.parse(selectedOption.body)
                      const resolvedBody = resolveTemplate(templateBody, emailTemplateData, t)
                      setBody(resolvedBody)
                      setInitialBody(resolvedBody)

                      const currentTemplate = emailTemplate

                      const templateAttachmentIds =
                        currentTemplate?.attachments?.map((a) => a.id) ?? []
                      let updatedAttachments = attachments.map((a) => ({
                        ...a,
                        isSelected: a.isSelected || templateAttachmentIds.includes(a.id),
                      }))
                      setAttachments(updatedAttachments)
                      setEmailTemplate(selectedOption)
                    }
                  }}
                  options={emailTemplates}
                  sx={{
                    "& .MuiSelect-select.MuiInputBase-input": {
                      paddingBottom: "4px",
                    },
                  }}
                  value={emailTemplate?.id ?? ""}
                  variant="outlined"
                />
              </Box>
              <Box sx={classes.fieldContainer}>
                <Autocomplete
                  clearOnBlur
                  freeSolo
                  id="toRecipients"
                  multiple
                  onBlur={() => {
                    if (toRecipients.length === 0 && tempRecipient.trim().length === 0) {
                      setErrors({
                        ...errors,
                        toRecipients: t("component.emailDialog.validation.toRecipients.required"),
                      })
                    }
                    if (tempRecipient.trim().length > 0) {
                      setToRecipients([...toRecipients, tempRecipient])
                    }
                  }}
                  onChange={(event, newValue: (string | string[])[]) => {
                    const updatedRecipients = [...newValue]
                    setToRecipients(updatedRecipients as string[])
                    if (updatedRecipients.length > 0) {
                      setErrors({ ...errors, toRecipients: null })
                    }
                  }}
                  onInputChange={(event, value) => {
                    setTempRecipient(value)
                  }}
                  options={[]}
                  renderInput={(params) => {
                    return (
                      <FielderTextField
                        {...params}
                        error={!!errors.toRecipients}
                        label={t("to")}
                        margin="dense"
                        placeholder={t("component.emailDialog.emailFieldPlaceholder") as string}
                        variant="outlined"
                      />
                    )
                  }}
                  renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={`${option}-${index}`}
                        label={option}
                        style={{
                          backgroundColor: isValidEmail(option) ? "#fff" : "#f03030",
                          color: isValidEmail(option) ? "#000" : "#fff",
                          fontWeight: 600,
                        }}
                        variant="outlined"
                      />
                    ))
                  }}
                  value={toRecipients}
                />
                {errors.toRecipients ? (
                  <FieldHelperText error message={errors.toRecipients} />
                ) : null}
              </Box>
              <Box sx={classes.fieldContainer}>
                <Autocomplete
                  clearOnBlur
                  freeSolo
                  id="ccRecipients"
                  multiple
                  onBlur={() => {
                    if (tempRecipient.trim().length > 0) {
                      setCcRecipients([...ccRecipients, tempRecipient])
                    }
                  }}
                  onChange={(event: any, newValue: (string | string[])[]) => {
                    setCcRecipients(newValue as string[])
                  }}
                  onInputChange={(event, value) => {
                    setTempRecipient(value)
                  }}
                  options={[]}
                  renderInput={(params) => {
                    return (
                      <FielderTextField
                        {...params}
                        label="CC"
                        margin="dense"
                        placeholder={t("component.emailDialog.emailFieldPlaceholder") as string}
                        variant="outlined"
                      />
                    )
                  }}
                  renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={`${option}-${index}`}
                        label={option}
                        style={{
                          backgroundColor: isValidEmail(option) ? "#fff" : "#f03030",
                          color: isValidEmail(option) ? "#000" : "#fff",
                          fontWeight: 600,
                        }}
                        variant="outlined"
                      />
                    ))
                  }}
                  value={ccRecipients}
                />
              </Box>
              <Box sx={classes.fieldContainer}>
                <Autocomplete
                  clearOnBlur
                  freeSolo
                  id="bccRecipients"
                  multiple
                  onBlur={() => {
                    if (tempRecipient.trim().length > 0) {
                      setBccRecipients([...bccRecipients, tempRecipient])
                    }
                  }}
                  onChange={(event: any, newValue: (string | string[])[]) => {
                    setBccRecipients(newValue as string[])
                  }}
                  onInputChange={(event, value) => {
                    setTempRecipient(value)
                  }}
                  options={[]}
                  renderInput={(params) => {
                    return (
                      <FielderTextField
                        {...params}
                        label="BCC"
                        margin="dense"
                        placeholder={t("component.emailDialog.emailFieldPlaceholder") as string}
                        variant="outlined"
                      />
                    )
                  }}
                  renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        key={`${option}-${index}`}
                        label={option}
                        style={{
                          backgroundColor: isValidEmail(option) ? "#fff" : "#f03030",
                          color: isValidEmail(option) ? "#000" : "#fff",
                          fontWeight: 600,
                        }}
                        variant="outlined"
                      />
                    ))
                  }}
                  value={bccRecipients}
                />
              </Box>
              <Box sx={classes.fieldContainer}>
                <FielderTextField
                  autoComplete="none"
                  data-testid="subject"
                  error={!!errors.subject}
                  fullWidth
                  id="subject"
                  inputProps={{ maxLength: 50 }}
                  label={t("subject")}
                  margin="dense"
                  name="subject"
                  onBlur={() => {
                    if (!subject || subject.trim().length === 0) {
                      setErrors({
                        ...errors,
                        subject: t("component.emailDialog.validation.subject.required"),
                      })
                    }
                  }}
                  onChange={(e) => {
                    setSubject(e.target.value)
                    if (e.target.value) {
                      setErrors({ ...errors, subject: null })
                    }
                  }}
                  required
                  value={subject}
                  variant="outlined"
                />
                {errors.subject ? <FieldHelperText error message={errors.subject} /> : null}
              </Box>
              <Box
                sx={{
                  marginTop: "-0.3125rem",
                  marginBottom: "1.25rem",
                  minHeight: "58px",
                }}
              >
                <Box
                  sx={{
                    marginLeft: "0.625rem",
                    marginBottom: "0.3125rem",
                  }}
                >
                  {t("attachments")}
                </Box>
                <Box {...getRootProps({ css: "dropzone" })}>
                  <input {...getInputProps()} />
                  <EmailAttachments
                    availableFiles={attachments}
                    isUploading={uploadingNewAttachmentFiles}
                    onClickUploadFromComputer={openFileDialog}
                    onDeselectAttachment={toggleAttachment}
                    onSelectAttachment={toggleAttachment}
                  />
                </Box>
              </Box>
              <Box
                id="job-email-dialog-composer"
                sx={{
                  flexGrow: 1,
                  overflow: "hidden",
                  display: "flex",
                  flexDirection: "column",
                  minHeight: "200px",
                }}
              >
                <RichTextComposer onChange={setBody} value={initialBody} />
              </Box>
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions
        className="drag-handle"
        sx={{
          backgroundColor: dialogBackgroundColor,
          borderTop: "1px solid #cdcdcd",
          padding: "1.5rem",
          paddingTop: "0.75rem",
          paddingBottom: "0.75rem",
          display: "flex",
          cursor: "move",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="secondary"
          data-testid="cancelButton"
          disabled={loading}
          onClick={onCancel}
          variant="outlined"
        >
          {t("cancel")}
        </Button>
        <Button
          color="primary"
          data-testid="sendButton"
          disabled={loading || !validate()}
          onClick={() => {
            if (validate()) {
              const estimateIds = estimates?.map((e) => e?.id) as string[]
              const workOrderIds = workOrders?.map((w) => w?.id) as string[]
              const invoiceIds = invoices?.map((i) => i.id) as string[]
              const checkListId = attachments.find((a) => a.type === "CHECKLIST")?.id
              const customAttachments = []
              const attachmentIds = attachments?.filter((a) => a.isSelected).map((a) => a.id)
              const specialAttachmentIds = estimateIds.concat(workOrderIds).concat(invoiceIds)
              if (checkListId && attachmentIds.includes(checkListId)) {
                specialAttachmentIds.push(checkListId)
                customAttachments.push({
                  id: checkListId,
                  typeName: "CHECKLIST",
                })
              }
              const generalAttachmentIds = difference(attachmentIds, specialAttachmentIds).filter(
                (i) => !!i
              ) as string[]
              const selectedEstimateIds = estimates
                ?.filter((e) => attachmentIds.includes(e.id))
                ?.map((q) => q.id)
                .filter((i) => !!i) as string[]
              const selectedWorkOrderIds = workOrders
                ?.filter((e) => attachmentIds.includes(e.id))
                ?.map((q) => q.id)
                .filter((i) => !!i) as string[]
              const selectedInvoiceIds = invoices
                ?.filter((i) => attachmentIds.includes(i.id))
                ?.map((i) => i.id)
                .filter((i) => !!i) as string[]

              const htmlBody = toHtml(body)

              const payload = {
                jobId: job.id,
                toRecipients,
                ccRecipients,
                bccRecipients,
                subject,
                body: htmlBody,
                attachmentIds: generalAttachmentIds,
                estimateIds: selectedEstimateIds,
                workOrderIds: selectedWorkOrderIds,
                invoiceIds: selectedInvoiceIds,
                customAttachments,
              }
              onSend(payload)
            }
          }}
          sx={{
            fontWeight: "bold",
            "& svg": {
              fontSize: "1.0rem",
            },
            "& div": {
              marginLeft: "0.625rem",
              marginRight: "0.625rem",
            },
            minWidth: "150px",
          }}
          variant="contained"
        >
          {loading ? (
            <CircularProgress color="secondary" size={20} thickness={6.0} />
          ) : (
            <Box>{t("sendEmail")}</Box>
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const dialogBackgroundColor = "#FFFFFF"
const classes = {
  fieldContainer: {
    marginBottom: "1.25rem",
  },
} as const

export default JobEmailDialog
